@charset "UTF-8";
a.itext {
  width: auto !important;
  height: auto !important;
}

.annot {
  display: none !important;
}

::selection {
  background-color: #d0d0ff;
}

.pcnone {
  display: none;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .pcnone {
    display: block;
  }
}

@media screen and (max-width: 640px) {
  .spnone {
    display: none;
  }
}

@media only screen and (min-width: 834px) and (max-width: 1200px) {
  .bp1200none {
    display: none;
  }
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .bp1200none {
    display: block;
  }
}

@media only screen and (max-width: 1200px) {
  .bp1000none {
    display: none;
  }
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .bp1000none {
    display: block;
  }
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

li {
  list-style: none;
}

html {
  font-size: 62.5% !important;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-weight: 400;
}

body {
  counter-reset: number 0;
  line-height: 1.7em;
  letter-spacing: 0.05em;
  font-weight: 400;
  color: #333;
  word-wrap: break-word;
  overflow: hidden;
  font-size: 1.4rem;
  font-family: "游ゴシック", YuGothic, "Noto Sans JP", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  background: url(../images/common/bg_body.jpg);
}

.inner {
  margin: 0 auto;
  max-width: 1100px;
}

@media only screen and (min-width: 834px) and (max-width: 1200px) {
  .inner {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media screen and (max-width: 834px) {
  .inner {
    padding-left: 24px;
    padding-right: 24px;
  }
}

#contents_wrap {
  width: 100%;
  min-width: 100%;
}

#contents {
  width: 100%;
  max-width: 1100px;
  padding-bottom: 100px;
  display: flex;
  justify-content: space-between;
  margin-top: 80px;
  flex-wrap: wrap;
}

@media only screen and (min-width: 834px) and (max-width: 1200px) {
  #contents {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media only screen and (min-width: 834px) and (max-width: 900px) {
  #contents {
    display: block;
  }
}

@media screen and (max-width: 834px) {
  #contents {
    padding-left: 24px;
    padding-right: 24px;
    display: block;
  }
}

#main {
  width: calc(100% - 350px);
}

@media only screen and (min-width: 834px) and (max-width: 900px) {
  #main {
    width: 100%;
  }
}

@media screen and (max-width: 834px) {
  #main {
    width: 100%;
  }
}

#main #col_main {
  width: 100%;
}

#side {
  width: calc(100% - (100% - 350px));
}

@media only screen and (min-width: 834px) and (max-width: 900px) {
  #side {
    width: 100%;
    margin: 60px auto 0;
  }
}

@media screen and (max-width: 834px) {
  #side {
    width: 100%;
    margin: 60px auto 0;
  }
}

.map li {
  border-left: 5px solid #CCCCCC;
  list-style: none;
  margin: 0 0 15px;
  padding: 0 0 0 10px;
  text-align: left;
}

table {
  table-layout: auto;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  table {
    table-layout: fixed;
  }
}

.tlfix table {
  table-layout: fixed;
}

table td img {
  height: auto !important;
}

.tdpd10 td {
  padding: 10px !important;
}

pre {
  margin: 0;
  padding: 0;
  white-space: pre-line;
}

b, strong {
  font-weight: bold;
}

u {
  text-decoration: line-through;
}

img {
  max-width: 100%;
}

hr {
  border: none;
  height: 0;
  border-bottom: 1px dashed #B3B3B3;
}

span {
  font-weight: inherit;
}

u {
  text-decoration: underline;
}

input[type="button"] {
  cursor: pointer;
  padding: 5px 10px;
}

a {
  color: #17e012;
  transition: 1s;
  text-decoration: none;
}

a:hover {
  opacity: 0.6;
  transition: 1s;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .imgR, .imgL {
    max-width: 30% !important;
  }
}

@media screen and (max-width: 640px) {
  .imgR, .imgL {
    display: block;
    float: none;
    margin: 0 auto 10px;
    max-width: 100% !important;
    text-align: center;
  }
}

.mincho {
  font-family: "游明朝体", "Yu Mincho", YuMincho, "Times New Roman", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.gothic {
  font-family: "游ゴシック", YuGothic, "Noto Sans JP", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@media screen and (max-width: 640px) {
  .fltL {
    float: none;
    width: 100% !important;
  }
  .fltR {
    float: none;
    width: 100% !important;
  }
  .sptal {
    text-align: left !important;
  }
  .sptar {
    text-align: right !important;
  }
  .sptac {
    text-align: center !important;
  }
  .spcenter {
    text-align: center;
    display: block;
    margin-top: 10px;
  }
}

header#global_header {
  min-width: auto;
}

footer#global_footer {
  min-width: auto;
}

header#global_header {
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  height: auto;
  text-align: center;
}

#g-nav {
  position: relative;
  padding: 10px 0;
  width: fit-content;
  margin: auto;
}

#g-nav ul {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
}

#g-nav ul .active a:before {
  content: url(../images/common/bg_navion.png);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -8px;
}

#g-nav ul li {
  position: relative;
}

#g-nav ul li a {
  text-align: center;
  padding: 10px 30px;
  display: block;
  position: relative;
  color: #0028af;
  font-size: 1.12em;
  font-weight: bold;
  border-bottom: 2px solid #0028af;
  font-family: "游明朝体", "Yu Mincho", YuMincho, "Times New Roman", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

#g-nav ul li a:hover:before {
  content: url(../images/common/bg_navion.png);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -8px;
}

@media screen and (max-width: 834px) {
  #g-nav ul li a {
    display: block;
    padding: 15px;
    font-size: 1.5em;
    border-bottom: 1px solid #ccc;
    text-align: left;
    position: relative;
    font-weight: bold;
  }
  #g-nav ul li a:before {
    font-family: "Font Awesome 5 pro";
    content: "\f054";
    position: absolute;
    font-size: 14px;
    right: 5%;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

@media only screen and (min-width: 834px) and (max-width: 900px) {
  #g-nav ul li a {
    display: block;
    padding: 15px;
    font-size: 1.5em;
    border-bottom: 1px solid #ccc;
    text-align: left;
    position: relative;
    font-weight: bold;
  }
  #g-nav ul li a:before {
    font-family: "Font Awesome 5 pro";
    content: "\f054";
    position: absolute;
    font-size: 14px;
    right: 5%;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

@media only screen and (min-width: 834px) and (max-width: 900px) {
  #g-nav {
    /*position:fixed;にし、z-indexの数値を大きくして前面へ*/
    position: fixed;
    z-index: 9999;
    /*ナビのスタート位置と形状*/
    top: -120%;
    left: 0;
    right: 0;
    height: 100vh;
    /*動き*/
    transition: all 0.6s;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  /*アクティブクラスがついたら位置を0に*/
  #g-nav.panelactive {
    background-color: #fff;
    padding: 50px;
    top: 0;
    width: 100%;
  }
  #g-nav.panelactive ul {
    display: block;
  }
}

@media screen and (max-width: 834px) {
  #g-nav {
    /*position:fixed;にし、z-indexの数値を大きくして前面へ*/
    position: fixed;
    z-index: 9999;
    /*ナビのスタート位置と形状*/
    top: -120%;
    left: 0;
    right: 0;
    height: 100vh;
    /*動き*/
    transition: all 0.6s;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #g-nav ul {
    display: block !important;
  }
  /*アクティブクラスがついたら位置を0に*/
  #g-nav.panelactive {
    background-color: #fff;
    padding: 50px;
    top: 0;
    width: 100%;
  }
  #g-nav.panelactive ul {
    display: block;
  }
}

.dropdown {
  width: 250px;
  position: absolute;
  left: 50%;
  top: 70px;
  transform: translateX(-50%);
  z-index: 9999;
}

@media screen and (max-width: 834px) {
  .dropdown {
    position: inherit;
    transform: none;
    top: 0;
    left: 0;
    margin: auto;
  }
}

.dropdown li a {
  background-color: white;
  display: block;
  color: #333 !important;
  border-bottom: 1px solid #ccc;
  padding: 15px !important;
}

@media screen and (max-width: 640px) {
  .dropdown li a {
    border-bottom: none;
  }
}

.toggle {
  position: absolute;
  right: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  z-index: -1;
}

@media screen and (max-width: 834px) {
  .toggle {
    z-index: 100;
  }
}

/*ハンバーガーメニュー*/
.openBtn {
  display: none;
}

@media only screen and (min-width: 834px) and (max-width: 900px) {
  .openBtn {
    display: block;
  }
}

@media screen and (max-width: 834px) {
  .openBtn {
    display: block;
  }
}

/*ボタン外側※レイアウトによってpositionや形状は適宜変更してください*/
.openBtn {
  position: fixed;
  /*ボタン内側の基点となるためrelativeを指定*/
  top: 0;
  right: 0;
  cursor: pointer;
  width: 80px;
  height: 80px;
  background: #0028af;
  box-shadow: -29px 28px 41px -49px #dcdada;
  z-index: 10000;
}

.btnArea {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

/*ボタン内側*/
.openBtn span {
  display: inline-block;
  transition: all .4s;
  /*アニメーションの設定*/
  height: 2px;
  border-radius: 5px;
  background: #fff;
  width: 35%;
}

.openBtn span:nth-of-type(2) {
  margin: 5px 0;
}

/*activeクラスが付与されると線が回転して×になり、Menu⇒Closeに変更*/
.openBtn.active span:nth-of-type(1) {
  top: 14px;
  left: 18px;
  transform: translateY(5px) rotate(-45deg);
  width: 40%;
}

.openBtn.active span:nth-of-type(2) {
  opacity: 0;
}

.openBtn.active span:nth-of-type(3) {
  top: 26px;
  left: 18px;
  transform: translateY(-8px) rotate(45deg);
  width: 40%;
}

.h-contact {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  z-index: 9999;
}

@media screen and (max-width: 834px) {
  .h-contact {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
  }
}

@media only screen and (min-width: 834px) and (max-width: 900px) {
  .h-contact {
    position: fixed;
    left: 0;
    bottom: 0;
  }
}

.h-contact .h_tel {
  color: #062e65;
  font-weight: bold;
  font-size: 1.5em;
  display: block;
  padding: 0 30px;
  font-family: 'Roboto', sans-serif;
  background-color: #fff;
}

.h-contact .h_tel span {
  display: block;
  font-size: 10px;
  font-weight: 400;
}

@media screen and (max-width: 834px) {
  .h-contact .h_tel {
    padding: 16px;
  }
}

@media only screen and (min-width: 834px) and (max-width: 900px) {
  .h-contact .h_tel {
    padding: 16px;
  }
}

.h-contact .h_btn {
  padding: 30px;
  background-color: #9b001a;
  color: #fff;
  font-weight: bold;
  font-size: 1.12em;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 240px;
}

@media screen and (max-width: 640px) {
  .h-contact a {
    width: calc(100% / 2) !important;
  }
}

.h-contact a img {
  margin-right: 5px;
}

.header {
  width: 100%;
  z-index: 9999;
}

.local_header {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@media only screen and (min-width: 834px) and (max-width: 900px) {
  .local_header {
    justify-content: flex-start;
  }
}

@media screen and (max-width: 834px) {
  .local_header {
    justify-content: flex-start;
  }
}

.mainArea {
  position: relative;
  width: fit-content;
  margin: auto;
}

.mainArea .h-logo {
  position: absolute;
  left: 0;
  top: 0;
}

@media only screen and (min-width: 834px) and (max-width: 900px) {
  .mainArea .h-logo {
    width: 40%;
  }
}

@media screen and (max-width: 834px) {
  .mainArea .h-logo {
    width: 30%;
  }
}

.subArea h2 {
  color: #0028af;
  font-size: clamp(30px, 3.6vw, 36px);
  font-weight: bold;
  line-height: 1.2em;
  font-family: "游明朝体", "Yu Mincho", YuMincho, "Times New Roman", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.subArea h2 span {
  color: #999999;
  font-size: 15px;
}

.pankuzu {
  margin-top: 12px;
  margin-bottom: 4.8rem;
  font-size: 1.4rem;
  display: flex;
  color: #333;
}

.pankuzu ul div::before {
  content: "\f105";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  padding: 0 10px;
}

.pankuzu ul li:last-child a {
  pointer-events: none;
  color: #21120e;
  text-decoration: none;
}

footer#global_footer {
  padding: 40px 0 20px;
  background-color: #0028af;
}

footer#global_footer .footer {
  display: flex;
  justify-content: center;
  position: relative;
}

footer#global_footer .footer .pagetop {
  position: absolute;
  right: 0;
  top: -98px;
}

@media screen and (max-width: 834px) {
  footer#global_footer .footer {
    display: block;
  }
}

footer#global_footer .ft_nav {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 80px;
}

@media screen and (max-width: 834px) {
  footer#global_footer .ft_nav {
    display: block;
  }
}

@media screen and (max-width: 834px) {
  footer#global_footer .ft_nav {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 640px) {
  footer#global_footer .ft_nav li {
    width: 100%;
  }
}

footer#global_footer .ft_nav li a {
  text-align: left;
  padding: 0 15px;
  display: block;
  font-size: 12px;
  line-height: 1em;
  color: #fff;
  border-right: 1px solid #fff;
  font-family: "游ゴシック", YuGothic, "Noto Sans JP", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  position: relative;
}

@media screen and (max-width: 834px) {
  footer#global_footer .ft_nav li a {
    padding: 15px;
    text-align: center;
    border-right: none;
  }
  footer#global_footer .ft_nav li a:before {
    font-family: "Font Awesome 5 pro";
    content: "\f054";
    position: absolute;
    font-size: 14px;
    right: 5%;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

footer#global_footer small {
  font-size: 12px;
  font-family: "游ゴシック", YuGothic, "Noto Sans JP", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

footer#global_footer #copy {
  color: #fff !important;
  margin-top: auto;
}

footer#global_footer #copy a {
  font-size: 12px;
  font-family: "游ゴシック", YuGothic, "Noto Sans JP", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  text-decoration: none;
  color: #fff !important;
}

.title_01 h2 {
  background: url(../images/home/bg_h2.png) no-repeat center;
  box-sizing: border-box;
  color: #0028af;
  display: block;
  font-size: 21px;
  line-height: 1;
  padding: 19px 0 19px;
  font-weight: bold;
  font-family: "游明朝体", "Yu Mincho", YuMincho, "Times New Roman", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.cont_01 {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 834px) {
  .cont_01 {
    display: block;
  }
}

.cont_01 .flxL {
  width: 48%;
}

.cont_01 .flxL img {
  width: 100% !important;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .cont_01 .flxL .imgL {
    max-width: 100% !important;
    float: none !important;
  }
}

@media screen and (max-width: 834px) {
  .cont_01 .flxL {
    width: 100% !important;
    text-align: center;
  }
}

.cont_01 .flxR {
  width: 48%;
}

@media screen and (max-width: 834px) {
  .cont_01 .flxR {
    width: 100% !important;
    margin-top: 15px;
  }
}

.cont_01 .movie iframe {
  width: 100%;
}

.dl_keireki, .news {
  max-height: 350px;
  overflow-y: auto;
}

.dl_keireki dt, .news dt {
  border: none !important;
  width: 100% !important;
}

.dl_keireki dd, .news dd {
  border: none !important;
  width: 100% !important;
}

.dl_keireki dl, .news dl {
  display: block !important;
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
}

@media only screen and (min-width: 834px) and (max-width: 900px) {
  #col_side2 {
    float: none !important;
    margin: auto;
  }
}

@media screen and (max-width: 834px) {
  #col_side2 {
    float: none !important;
    margin: auto;
  }
}

#col_side2 .side_sub_title {
  color: #0028af;
  text-align: left;
  margin-bottom: 20px;
}

#col_side2 .side_text {
  text-align: left;
}

#col_side2 .banner {
  margin-bottom: 30px;
  text-align: center;
}

@media screen and (max-width: 640px) {
  #col_side2 .banner img {
    width: 100% !important;
  }
}

#col_side2 .banner:last-of-type {
  margin-bottom: 0;
}

#col_side2 #right_8 img {
  width: auto !important;
}

.text div {
  font-family: "游ゴシック", YuGothic, "Noto Sans JP", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  line-height: 24px;
  font-size: 14px;
}

.flexbox_01 {
  justify-content: space-between;
}

.flexbox_01 .box {
  width: calc((100% - 100px) / 3);
  box-shadow: 0px 10px 10px -6px rgba(0, 0, 0, 0.3);
}

.flexbox_01 .box article > div {
  font-size: 1.12em;
  font-weight: bold;
  background-color: #fff;
  padding: 20px;
  text-align: center;
  color: #062e65;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .flexbox_01 .box {
    width: 48%;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 640px) {
  .flexbox_01 .box {
    width: 100%;
    margin-bottom: 20px;
  }
}

.flexbox_01 .box img {
  width: 100% !important;
}

.flexbox_02 {
  justify-content: space-between;
}

.flexbox_02 .box {
  width: calc((100% - 100px) / 3);
  position: relative;
}

.flexbox_02 .box img {
  transition: 0.7s;
}

.flexbox_02 .box img:hover {
  transform: scale(1.05);
}

.flexbox_02 .box article > div a {
  display: block;
  line-height: 1.25em;
  position: absolute;
  left: 10%;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
  font-size: 2em;
  font-weight: bold;
}

.flexbox_02 .box article > div a .ja {
  display: block;
  font-size: 0.5em;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .flexbox_02 .box {
    width: 48%;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 640px) {
  .flexbox_02 .box {
    width: 100%;
    margin-bottom: 20px;
  }
}

.flexbox_02 .box img {
  width: 100% !important;
}

.btn {
  position: relative;
  z-index: 2;
}

.btn a {
  color: #fff;
  font-weight: bold;
  font-size: 1.5em;
  position: relative;
  display: block;
  padding: 10px;
  background-color: #0028af;
  width: 200px;
  font-family: "游明朝体", "Yu Mincho", YuMincho, "Times New Roman", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  margin: auto;
}

.btn a:hover {
  background-color: #FF8901;
}

.btnbox {
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
}

@media screen and (max-width: 640px) {
  .btnbox {
    padding: 0 70px;
  }
}

@media screen and (max-width: 640px) {
  .btnbox {
    padding: 0 30px;
  }
}

.btnbox .box {
  width: calc((100% - 50px) / 2);
  background-color: #fff;
  text-align: center;
}

.btnbox .box:nth-of-type(2) {
  margin: 40px 0;
  border-left: 1px solid #e5e5e5;
}

@media screen and (max-width: 834px) {
  .btnbox .box:nth-of-type(2) {
    margin: 0;
    padding: 30px;
    border-top: 1px solid #e5e5e5;
    border-left: none;
  }
}

@media screen and (max-width: 834px) {
  .btnbox .box {
    width: 100%;
  }
}

.btnbox .box .h_tel {
  color: #062e65;
  font-weight: bold;
  font-size: clamp(30px, 4vw, 40px);
  display: block;
  padding: 0 30px;
  font-family: 'Roboto', sans-serif;
}

.btnbox .box .h_tel span {
  display: block;
  font-size: 12px;
  font-weight: 400;
  margin-top: 16px;
}

@media screen and (max-width: 834px) {
  .btnbox .box .h_tel {
    padding: 30px;
  }
}

.btnbox .box .h_btn {
  padding: 30px;
  background-color: #9b001a;
  color: #fff;
  font-weight: bold;
  font-size: 1.12em;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 320px;
  margin: auto;
}

@media screen and (max-width: 640px) {
  .btnbox .box .h_btn {
    width: 100%;
  }
}

.btnbox .box a img {
  margin-right: 5px;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: none;
  border: none;
  border-radius: 10px;
  box-shadow: inset 0 0 2px #ccc;
}

::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 10px;
  box-shadow: none;
}

div.items > article a {
  color: #111;
  text-decoration: none !important;
  font-size: 16px;
}

div.under ul li a {
  font-weight: 400;
  font-family: "游明朝体", "Yu Mincho", YuMincho, "Times New Roman", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  opacity: 1;
  border: 1px solid #333;
  color: #333 !important;
  padding: 10px;
}

div.under ul li a:hover {
  border: 1px solid #b0ddd9;
  background: #b0ddd9;
  color: #fff !important;
}

div.select > select {
  height: 100%;
}

div.search form input {
  padding: 10px 37px 10px 5px;
}

div.order ul li a {
  color: #111;
}

div.items > article p.price {
  color: red;
}

div.crumb {
  display: none;
}

div.item_view div.item_option {
  width: 100% !important;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .addcart {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

div.item_view div.struct {
  display: block;
}

div.photo div.large {
  width: auto;
}

div.photo {
  margin-left: 0;
}

h2.title_h2 {
  background: none !important;
  border-top: none !important;
  border-bottom: 1px solid #CCCCCC;
  font-size: 20px !important;
  line-height: 1.3;
  margin: 65px 0 20px !important;
  padding: 10px !important;
}

h2.title_h2 span {
  border: none !important;
  padding: 0 !important;
}

ul.item_list li {
  margin-bottom: 10px !important;
}

ul.item_list li h3.title_h3 {
  font-size: 18px !important;
}

.btn_cart a {
  background: url(../images/common/ico_cart_w.png) no-repeat 10px 12px #000000 !important;
  background-size: 30px auto !important;
  color: #FFFFFF !important;
  display: block !important;
  padding: 10px 10px 10px 50px !important;
  width: 100% !important;
  border: 1px solid #ffffff;
}

.btn_cart a:hover {
  background: url(../images/common/ico_cart_w.png) no-repeat 10px 12px #CCCCCC !important;
  background-size: 30px auto !important;
}

.sort_catalog {
  overflow: hidden;
}

.sort_catalog ul {
  display: flex;
}

.item_title, .wishlist {
  margin: 0 !important;
}

.shop_cont h2.title_h2, .item_info h2.title_h2 {
  border-top: 2px solid #CCCCCC;
}

.shop_cont a, .item_info a {
  color: #CCCCCC;
}

.shop_cont a:hover, .item_info a:hover {
  text-decoration: none;
}

.item_title h2 {
  border-top: 2px solid #CCCCCC;
  border-bottom: 1px solid #CCCCCC;
}

.shop_cont table {
  table-layout: auto;
}

.flex1 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flex1._top {
  align-items: flex-start;
}

.flex1._center {
  align-items: center;
}

.flex1:before {
  width: calc(100% / 1 - 1%);
  content: "";
  order: 1;
}

.flex1:before {
  width: calc(100% / 1 - 1%);
  content: "";
  order: 1;
}

.flex1:after {
  width: calc(100% / 1 - 1%);
  content: "";
  order: 2;
}

.flex1 .box {
  width: calc(100% / 1 - 1%);
  margin-bottom: 2%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  ._tabwidth1 .box {
    width: 100%;
  }
  ._tabwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._tabwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

@media screen and (max-width: 640px) {
  ._spwidth1 .box {
    width: 100%;
  }
  ._spwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._spwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

.flex1 .box img {
  width: 100% !important;
}

.flex2 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flex2._top {
  align-items: flex-start;
}

.flex2._center {
  align-items: center;
}

.flex2:before {
  width: calc(100% / 2 - 1%);
  content: "";
  order: 1;
}

.flex2:before {
  width: calc(100% / 2 - 1%);
  content: "";
  order: 1;
}

.flex2:after {
  width: calc(100% / 2 - 1%);
  content: "";
  order: 2;
}

.flex2 .box {
  width: calc(100% / 2 - 1%);
  margin-bottom: 2%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  ._tabwidth1 .box {
    width: 100%;
  }
  ._tabwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._tabwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

@media screen and (max-width: 640px) {
  ._spwidth1 .box {
    width: 100%;
  }
  ._spwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._spwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

.flex2 .box img {
  width: 100% !important;
}

.flex3 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flex3._top {
  align-items: flex-start;
}

.flex3._center {
  align-items: center;
}

.flex3:before {
  width: calc(100% / 3 - 1%);
  content: "";
  order: 1;
}

.flex3:before {
  width: calc(100% / 3 - 1%);
  content: "";
  order: 1;
}

.flex3:after {
  width: calc(100% / 3 - 1%);
  content: "";
  order: 2;
}

.flex3 .box {
  width: calc(100% / 3 - 1%);
  margin-bottom: 2%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  ._tabwidth1 .box {
    width: 100%;
  }
  ._tabwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._tabwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

@media screen and (max-width: 640px) {
  ._spwidth1 .box {
    width: 100%;
  }
  ._spwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._spwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

.flex3 .box img {
  width: 100% !important;
}

.flex4 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flex4._top {
  align-items: flex-start;
}

.flex4._center {
  align-items: center;
}

.flex4:before {
  width: calc(100% / 4 - 1%);
  content: "";
  order: 1;
}

.flex4:before {
  width: calc(100% / 4 - 1%);
  content: "";
  order: 1;
}

.flex4:after {
  width: calc(100% / 4 - 1%);
  content: "";
  order: 2;
}

.flex4 .box {
  width: calc(100% / 4 - 1%);
  margin-bottom: 2%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  ._tabwidth1 .box {
    width: 100%;
  }
  ._tabwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._tabwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

@media screen and (max-width: 640px) {
  ._spwidth1 .box {
    width: 100%;
  }
  ._spwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._spwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

.flex4 .box img {
  width: 100% !important;
}

.square._100 .square-inner:before {
  padding-top: 100%;
}

.square._75 .square-inner:before {
  padding-top: 75%;
}

.square._66 .square-inner:before {
  padding-top: 66%;
}

.square td .square-inner {
  display: block;
  position: relative;
}

.square td .square-inner:before {
  position: relative;
  z-index: 1;
  content: "";
  display: block;
  width: 100% !important;
}

.square td .square-inner img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  font-family: "object-fit:cover;";
  width: 100% !important;
  height: 100% !important;
}

.square > .square-inner {
  display: block;
  position: relative;
}

.square > .square-inner .itext {
  float: none !important;
  margin-right: 0 !important;
}

.square > .square-inner .itext img {
  float: none !important;
  margin-right: 0 !important;
}

.square > .square-inner:before {
  position: relative;
  z-index: 1;
  content: "";
  display: block;
  width: 100% !important;
}

.square > .square-inner img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  font-family: "object-fit:cover;";
  width: 100% !important;
  height: 100% !important;
}

.square > .box .itext {
  float: none !important;
  margin-right: 0 !important;
}

.square > .box .itext img {
  float: none !important;
  margin-right: 0 !important;
}

.square > .box .square-inner {
  display: block;
  position: relative;
}

.square > .box .square-inner:before {
  position: relative;
  z-index: 1;
  content: "";
  display: block;
  width: 100% !important;
}

.square > .box .square-inner img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  font-family: "object-fit:cover;";
  width: 100% !important;
  height: 100% !important;
}

.bg100 {
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
}

.flex2cl_01 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .flex2cl_01 {
    flex-direction: column-reverse;
  }
}

.flex2cl_01 .flxL {
  width: 57%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .flex2cl_01 .flxL {
    width: 100%;
  }
}

.flex2cl_01 .flxR {
  width: 40%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .flex2cl_01 .flxR {
    width: 100%;
    margin-bottom: 20px;
  }
}

.flex2cl_01 .flxR .img {
  width: 100%;
}

.flex2cl_01 .flxR .img img {
  width: 100% !important;
}

.flex2cl_02 {
  border: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -4%;
  margin-bottom: 50px;
}

@media screen and (max-width: 1100px) {
  .flex2cl_02 {
    margin-left: -2.4390%;
  }
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .flex2cl_02 {
    justify-content: space-between;
    margin-left: 0;
  }
}

.flex2cl_02 .box {
  position: relative;
  width: 46%;
  margin-left: 4%;
  margin-bottom: 20px;
}

@media screen and (max-width: 1100px) {
  .flex2cl_02 .box {
    width: 47.5609%;
    margin-left: 2.4390%;
    display: block;
  }
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .flex2cl_02 .box {
    width: 100%;
    margin-left: 0;
  }
}

.flex2cl_02 .box img {
  width: 100% !important;
}

.flex2cl_02 .box tr:nth-child(2) {
  font-size: 18px;
  font-weight: 700;
}

.flex2cl_02 .box tr td {
  padding: 0 !important;
  border: 0 !important;
}

.flex3cl {
  border: 0;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -2.43902%;
}

@media screen and (max-width: 1100px) {
  .flex3cl {
    margin-left: -2.4390%;
  }
}

@media screen and (max-width: 640px) {
  .flex3cl {
    justify-content: space-between;
    margin-left: 0;
  }
}

.flex3cl .box {
  position: relative;
  width: 30.89431%;
  margin-left: 2.43902%;
  margin-bottom: 30px;
}

@media screen and (max-width: 1100px) {
  .flex3cl .box {
    width: 47.5609%;
    margin-left: 2.4390%;
    display: block;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 640px) {
  .flex3cl .box {
    width: 100%;
    margin-left: 0;
    margin-bottom: 20px;
  }
}

.flex3cl .box img {
  width: 100% !important;
}

.flex3cl .box tr:nth-child(2) {
  font-size: 18px;
  font-weight: 700;
}

.flex3cl .box tr td {
  padding: 0 !important;
  border: 0 !important;
}

.flex4cl {
  border: 0;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -3.22581%;
}

@media screen and (max-width: 1100px) {
  .flex4cl {
    margin-left: -2.4390%;
  }
}

@media screen and (max-width: 640px) {
  .flex4cl {
    justify-content: space-between;
    margin-left: 0;
  }
}

.flex4cl .box {
  position: relative;
  width: 21.77419%;
  margin-left: 3.22581%;
  margin-bottom: 30px;
}

@media screen and (max-width: 1100px) {
  .flex4cl .box {
    width: 47.5609%;
    margin-left: 2.4390%;
    display: block;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 640px) {
  .flex4cl .box {
    width: 100%;
    margin-left: 0;
    margin-bottom: 20px;
  }
}

.flex4cl .box img {
  width: 100% !important;
}

.flex4cl .box tr:nth-child(2) {
  font-size: 18px;
  font-weight: 700;
}

.flex4cl .box tr td {
  padding: 0 !important;
  border: 0 !important;
}

.local_title_01 h2 {
  font-size: 2em;
  line-height: 1.2em;
  font-weight: bold;
  position: relative;
  padding: 20px 30px 20px 40px;
  background-color: #f7f7f7;
  border-radius: 10px;
  font-family: "游明朝体", "Yu Mincho", YuMincho, "Times New Roman", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.local_title_01 h2:before {
  position: absolute;
  top: 25%;
  left: 20px;
  width: 6px;
  height: 50%;
  content: '';
  border-radius: 3px;
  background: #0028af;
}

.sub_title_01 {
  border-bottom: 1px solid #0028af;
  padding-bottom: 5px;
}

.sub_title_01 h3 {
  font-size: 1.12em;
  line-height: 1.2em;
  font-weight: bold;
  border-left: 5px solid #0031b6;
  padding-left: 15px;
}

.sub_title_01 article > div {
  color: #0028af;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .img .imgL {
    max-width: 100% !important;
  }
}

.dl_01 {
  border: 1px solid #0028af;
}

.dl_01 dt {
  border: none !important;
  border-bottom: 1px solid #fff !important;
  padding: 15px !important;
  color: #fff;
  align-items: center !important;
}

@media screen and (max-width: 640px) {
  .dl_01 dt {
    display: block !important;
    width: 100% !important;
  }
}

.dl_01 dd {
  border: none !important;
  border-bottom: 1px solid #0028af !important;
  padding: 15px !important;
}

@media screen and (max-width: 640px) {
  .dl_01 dd {
    display: block !important;
    width: 100% !important;
  }
}

.dl_01 dl:last-of-type dt {
  border-bottom: none !important;
}

.dl_01 dl:last-of-type dd {
  border-bottom: none !important;
}

@media screen and (max-width: 640px) {
  .dl_01 dl {
    display: block !important;
  }
}

.ryakurekiBox .box {
  width: 100%;
}

.ryakurekiBox .box article > div {
  text-align: left;
  margin-top: 30px;
}

.ryakurekiBox .box article > div .bg {
  background-color: #804040;
  padding: 30px;
  color: #fff;
}

.ryakurekiBox .box article > div .bg2 {
  background-color: #FF8080;
  color: #fff;
  text-align: center;
  padding: 30px;
}

.ryakurekiBox .box article > div .bg2 img {
  margin: auto;
}

.ryakurekiBox .box article > div img {
  display: block;
  margin: 15px 0;
}

.ryakurekiBox .box article > div iframe {
  width: 100%;
}

/*アコーディオン全体*/
.accordion-area {
  list-style: none;
  width: 96%;
  max-width: 900px;
  margin: 0 auto;
}

.accordion-area li {
  margin: 10px 0;
}

.accordion-area section {
  border: 1px solid #ccc;
}

/*アコーディオンタイトル*/
.ryakurekiBox .box:first-of-type {
  position: relative;
  /*+マークの位置基準とするためrelative指定*/
  cursor: pointer;
  padding: 1.5rem 1.5rem 1.5rem 4.5rem;
  transition: all .5s ease;
  color: #fff;
  background-color: #0028af;
}

/*アイコンの＋と×*/
.ryakurekiBox .box:first-of-type::before,
.ryakurekiBox .box:first-of-type::after {
  position: absolute;
  content: '';
  width: 15px;
  height: 2px;
  background-color: #fff;
}

.ryakurekiBox .box:first-of-type::before {
  top: 48%;
  left: 15px;
  transform: rotate(0deg);
}

.ryakurekiBox .box:first-of-type::after {
  top: 48%;
  left: 15px;
  transform: rotate(90deg);
}

/*　closeというクラスがついたら形状変化　*/
.ryakurekiBox .box:first-of-type.close::before {
  transform: rotate(45deg);
}

.ryakurekiBox .box:first-of-type.close::after {
  transform: rotate(-45deg);
}

/*アコーディオンで現れるエリア*/
.ryakurekiBox .box:nth-of-type(2), .ryakurekiBox .box:nth-of-type(3) {
  display: none;
  /*はじめは非表示*/
  background: #fff;
  padding: 3rem;
  border: 1px solid #0028af;
}

.gallery_wrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.gallery_wrap .gallery_box {
  width: calc((100% - 60px) / 3);
  margin-bottom: 30px;
}

@media screen and (max-width: 640px) {
  .gallery_wrap .gallery_box {
    width: 100%;
  }
}

.gallery_wrap .gallery_box .box {
  width: 100%;
}

.gallery_wrap .gallery_box img {
  width: 100% !important;
  object-fit: cover;
}

.gallery_wrap .gallery_box h3 {
  font-weight: bold;
  color: #fff;
  text-align: center;
  background-color: #0028af;
  padding: 10px;
  line-height: 1.2em;
}

.gallery_wrap .gallery_box article > div {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  height: 50px;
}

.open_gallery {
  white-space: inherit !important;
  height: 100% !important;
}

.local_cont_01 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-left: 1px solid #0028af;
  border-right: 1px solid #0028af;
  border-bottom: 1px dashed #0028af;
  padding: 15px;
}

@media screen and (max-width: 834px) {
  .local_cont_01 {
    display: block;
  }
}

.local_cont_01 .flxL {
  width: 68%;
}

.local_cont_01 .flxL img {
  width: 100% !important;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .local_cont_01 .flxL .imgL {
    max-width: 100% !important;
    float: none !important;
  }
}

@media screen and (max-width: 834px) {
  .local_cont_01 .flxL {
    width: 100% !important;
    text-align: center;
  }
}

.local_cont_01 .flxR {
  width: 30%;
}

@media screen and (max-width: 834px) {
  .local_cont_01 .flxR {
    width: 100% !important;
    margin-top: 15px;
  }
}

.local_cont_01 .flxL {
  border-right: 1px dashed #0028af;
  padding-right: 15px;
}

.top_border {
  border-top: 1px solid #0028af;
}

.btm_border {
  border-bottom: 1px solid #0028af;
}

.imgbox {
  justify-content: space-between;
  align-items: center;
}

.imgbox .box {
  width: calc((100% - 40px) / 3);
}

.albumWrap {
  display: flex;
  background-color: #fff;
  border: 1px solid #0028af;
  padding: 3rem;
}

@media screen and (max-width: 640px) {
  .albumWrap {
    display: block;
  }
}

.albumWrap .list_01 {
  width: 48%;
}

@media screen and (max-width: 640px) {
  .albumWrap .list_01 {
    width: 100%;
  }
}

.textCont {
  border: 1px solid #0028af;
  background-color: #fff;
  padding: 40px;
}

div.items > article {
  width: 29% !important;
}

@media screen and (max-width: 640px) {
  div.items > article {
    width: 100% !important;
  }
}

div.items > article a.photo {
  width: 100% !important;
}

div.items {
  justify-content: space-between;
}

div.item_option dl {
  width: 100%;
}

@media only screen and (min-width: 834px) and (max-width: 900px) {
  article.item {
    display: block !important;
  }
}

@media only screen and (min-width: 834px) and (max-width: 900px) {
  div.item_view {
    max-width: 100% !important;
  }
}

.form dt {
  background-color: #0028af !important;
  color: #fff;
}

.list_map li {
  padding: 5px 5px 5px 3rem;
  border-left: 10px solid #333;
  margin-bottom: 20px;
}

.list_map li a {
  color: #333;
}

.list_map li a:hover {
  text-decoration: underline;
}

.map iframe {
  width: 100%;
}

.policy_cont h2 {
  font-size: 1.4em;
  border-bottom: 1px solid #0028af;
  padding-bottom: 15px;
  font-weight: bold;
  margin: 50px 0 15px 0;
  font-family: "游明朝体", "Yu Mincho", YuMincho, "Times New Roman", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.mail {
  display: none;
}

.confirm {
  margin-top: 50px;
}

.policy {
  padding: 10px 0;
  text-align: center;
}
